import throttle from 'lodash/throttle';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface HookParams {
    navigationItems: any[];
    navigationContainer: HTMLElement | null;
    navigation: HTMLElement | null;
    moreMenu: HTMLElement | null;
    minimumNumberInNav?: number;
    triggerRerender?: boolean | string;
}

export function usePriorityNavigation({
    navigationItems = [],
    navigationContainer,
    navigation,
    moreMenu,
    minimumNumberInNav = 4,
    triggerRerender,
}: HookParams) {
    const widthsArray = useRef<number[]>([]);
    const [moreItems, setMoreItems] = useState<any[]>([]);
    const [priorityItems, setPriorityItems] = useState<any[]>(navigationItems);

    const howManyItemsInMenuArray = useCallback(
        (array: number[], outerWidth: number, initialWidth: number) => {
            let total = initialWidth;

            for (let i = 0; i < array.length; i++) {
                if (total + array[i] > outerWidth - 150) {
                    return i < minimumNumberInNav ? minimumNumberInNav : i;
                } else {
                    total += array[i];
                }
            }
            return array.length;
        },
        [minimumNumberInNav],
    );

    const updateNavigation = useCallback(() => {
        if (!navigationContainer) return;

        const outerWidth = navigationContainer?.getBoundingClientRect().width;
        const moreMenuWidth = moreMenu ? moreMenu?.getBoundingClientRect().width : 64;

        const tempMoreItems = [...moreItems];

        const arrayAmount = howManyItemsInMenuArray(widthsArray.current, outerWidth, moreMenuWidth);

        const navItemsCopy = [...navigationItems];
        const currentPriorityItems = [...priorityItems];

        const newPriorityItems = navItemsCopy.slice(0, arrayAmount);
        const moreItemsAvailable = navItemsCopy.length > newPriorityItems.length;

        if (currentPriorityItems.length < arrayAmount && moreItemsAvailable) {
            const spaceAvailable = arrayAmount - currentPriorityItems.length;
            const itemsToMoveBack = tempMoreItems.slice(0, spaceAvailable);

            setPriorityItems(prev => [...prev, ...itemsToMoveBack]);
            setMoreItems(prev => prev.slice(itemsToMoveBack.length));
        } else {
            setPriorityItems(newPriorityItems);
            const moreItemsToShow = moreItemsAvailable ? navItemsCopy.slice(arrayAmount) : [];

            setMoreItems(moreItemsToShow);
        }
    }, [
        moreMenu,
        setMoreItems,
        priorityItems,
        navigationItems,
        setPriorityItems,
        navigationContainer,
        widthsArray?.current,
    ]);

    useLayoutEffect(() => {
        if (navigation && navigationItems) updateNavigation(); // For initial width calculation
    }, [navigation, navigationItems]);

    useEffect(() => {
        if (!navigation) return;

        widthsArray.current = Array.from(navigation.children).map(item => item.getBoundingClientRect().width);

        updateNavigation();

        const throttledCallback = throttle(updateNavigation, 200);

        window.addEventListener('resize', throttledCallback);

        return () => {
            window.removeEventListener('resize', throttledCallback);
        };
    }, [navigation, moreMenu, navigationItems, triggerRerender]);

    return [priorityItems, moreItems];
}
