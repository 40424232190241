import { proxy } from 'valtio';

export interface ISeasonItem {
    id: number;
    name: string;
    start_date: string;
    end_date: string;
}

type TSeasonStatus = 'active' | 'finished' | 'upcoming';

export interface ISeasonsConfigs {
    recent_season_id: number;
    recent_season_status: TSeasonStatus;
    seasons: ISeasonItem[];
}

interface SeasonsState {
    seasonsConfigsLoading: boolean;
    seasonsConfigs?: ISeasonsConfigs;
    recentSeason?: ISeasonItem;
    selectedSeason?: ISeasonItem;
    upcomingSeason?: ISeasonItem & {
        status: TSeasonStatus;
    };
}

const state = proxy<SeasonsState>({
    seasonsConfigsLoading: false,
});

const SeasonsStore = {
    state,

    setSeasonsConfigsLoading(payload: boolean) {
        state.seasonsConfigsLoading = payload;
    },

    setSeasonsConfigs(payload: ISeasonsConfigs) {
        state.seasonsConfigs = payload;
        state.seasonsConfigsLoading = false;
    },

    setSelectedSeason(payload?: ISeasonItem) {
        state.selectedSeason = payload;
    },

    setRecentSeason(payload?: ISeasonItem) {
        state.recentSeason = payload;
    },

    setUpcomingSeason(payload?: ISeasonItem & { status: TSeasonStatus }) {
        state.upcomingSeason = payload;
    },
};

export default SeasonsStore;
